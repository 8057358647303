import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AlertController, NavController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { LocationStateService } from "../state/location-state.service";
import { LocationStateQuery } from "../state/location-state.query";
import { ActionsService } from "../state/actions.service";
import { RidePosition } from "../state/location-state.store";
import { firstValueFrom } from "rxjs";
import { OperatorLoginModal } from "source/app/components/operator-login/modals/operator-login.modal";
import { ChangePinCodeModal } from "source/app/modals/change-pin-code/change-pin-code.modal";

@Injectable({ providedIn: "root" })
export class OperatorService {
	constructor(
		private apiService: ApiService,
		private operatorLoginModal: OperatorLoginModal,
		private changePinModal: ChangePinCodeModal,
		private locationStateService: LocationStateService,
		private locationStateQuery: LocationStateQuery,
		private actionsService: ActionsService,
		private alertController: AlertController,
		private navController: NavController,
		private transloco: TranslocoService,
	) {}

	currentOperator$ = this.locationStateQuery.selectOperator$;

	selectOperator = async (operator: Operator) => {
		const currentOperator = await this.currentOperator$.firstAsync();
		if (currentOperator?.id === operator.id) {
			return this.logoutOperator();
		}

		if (operator.hasPinCode === false) {
			return this.showMissingPinCodeAlert();
		}
		if (operator.missingCompetencies?.length > 0) {
			const requiredCompetencies = await this.locationStateQuery.requiredCompetencies$.firstAsync();
			return this.showMissingCompetenciesAlert(operator, requiredCompetencies);
		}

		await this.showPinCodeModal(operator);
	};

	selectAttendant = async (attendant: Operator, ridePosition: RidePosition): Promise<boolean> => {
		if (ridePosition.attendant?.user.id === attendant.id) {
			this.signOutAttendant(attendant, ridePosition);
			return true;
		}
		if (attendant.missingCompetencies?.length > 0) {
			const requiredCompetencies = await this.locationStateQuery.requiredCompetenciesForAttendants$.firstAsync();

			await this.showMissingCompetenciesAlert(attendant, [
				...ridePosition.requiredCompetencies,
				...requiredCompetencies,
			]);

			return false;
		} else {
			return await this.signInAttendant(attendant, ridePosition);
		}
	};

	populateMissingCompetencies = (operators: Operator[], requiredCompetencies: DocumentReference[]): Operator[] => {
		return operators.map((operator) => {
			const missingCompetencies = requiredCompetencies
				.map((x) => x.id)
				.filter((competency) => operator.competencies?.includes(competency) === false);

			return {
				...operator,
				missingCompetencies,
			};
		});
	};

	login = async (userId: string, pin: string): Promise<void> => {
		await this.apiService.login(userId, pin);
	};

	signInAttendant = async (attendant: Attendant, position: RidePosition): Promise<boolean> => {
		try {
			await this.apiService.signInAttendant(attendant.id, position.identifier);
		} catch {
			const alert = await this.alertController.create({
				header: this.transloco.translate("ATTENDANT_SIGNIN_FAILED__HEADER"),
				message: this.transloco.translate("ATTENDANT_SIGNIN_FAILED__MESSAGE"),
				buttons: [this.transloco.translate("ATTENDANT_SIGNIN_FAILED__OK")],
			});

			alert.present();
			return false;
		}
		await this.locationStateService.setAttendantOnPosition(attendant, position);
		return true;
	};

	signOutAttendant = async (attendant: Attendant, position: RidePosition) => {
		try {
			await this.locationStateService.removeAttendantOnPosition(position);
			await this.apiService.signOutAttendant(attendant.id, position.identifier);
		} catch {
			await this.locationStateService.setAttendantOnPosition(attendant, position);
		}
	};

	logoutOperator = async (skipNavigate = false) => {
		try {
			await this.apiService.logout();
		} catch {
			this.actionsService.addSignOutAction();
		}

		await this.locationStateService.clearOperator(skipNavigate);
	};

	private showPinCodeModal = async (selectedOperator: Operator): Promise<void> => {
		const operator: Operator = await this.operatorLoginModal.show(selectedOperator);
		if (operator === undefined) {
			return;
		}

		this.locationStateService.setCurrentOperator(operator);

		if (operator.pinCodeUsed === false) {
			await this.showChangePinCodeModal();
		}

		this.navController.navigateRoot("dashboard");
	};

	private showMissingPinCodeAlert = async (): Promise<void> => {
		const alert = await this.alertController.create({
			header: this.transloco.translate("OPERATOR_NO_PIN__TITLE"),
			message: this.transloco.translate("OPERATOR_NO_PIN__MESSAGE"),
			buttons: [this.transloco.translate("OPERATOR_NO_PIN__OK")],
		});

		await alert.present();
	};

	private showChangePinCodeModal = async (): Promise<void> => {
		const alertNewPin = await this.alertController.create({
			message: this.transloco.translate("OPERATOR_LOGIN__PIN_FIRST_USE"),
			buttons: [this.transloco.translate("OPERATOR_LOGIN__OK")],
		});

		await alertNewPin.present();
		await alertNewPin.onDidDismiss();

		await this.changePinModal.show({
			requireCurrentPin: false,
		});
	};

	private showMissingCompetenciesAlert = async (
		operator: Operator,
		requiredCompetencies: DocumentReference[],
	): Promise<void> => {
		const missingCompetenices = operator.missingCompetencies
			.map((missingCompetency) => requiredCompetencies.find((competency) => competency.id == missingCompetency))
			.filter((competency) => competency != null)
			.map((competency) => "<li>" + competency.name + "</li>");

		const missingCompetenciesString = "<ul>" + missingCompetenices.join("") + "</ul>";

		const alert = await this.alertController.create({
			header: this.transloco.translate("OPERATOR_MISSING_COMPETENCIES__TITLE"),
			message: this.transloco.translate("OPERATOR_MISSING_COMPETENCIES__MESSAGE") + missingCompetenciesString,
			buttons: [this.transloco.translate("OPERATOR_MISSING_COMPETENCIES__OK")],
			cssClass: "missing-competencies-alert",
		});

		await alert.present();
	};
}
