import { ChangeDetectionStrategy, Component, OnInit, forwardRef } from "@angular/core";
import { SelectQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-select-question",
	templateUrl: "./select-question.component.html",
	styleUrls: ["./select-question.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectQuestionComponent),
			multi: true,
		},
	],
})
export class SelectQuestionComponent extends QuestionComponent<SelectQuestion> implements OnInit, ControlValueAccessor {
	isOptionSelected = (option: string) => this.answer?.answers?.includes(option);

	isSingleLineMode: boolean;
	select = (option: string) => {
		const currentAnswers = this.answer?.answers || [];
		const isOptionAlreadySelected = this.isOptionSelected(option);

		this.onTouched();

		if (this.question.variation === "Multiple") {
			const answers = isOptionAlreadySelected
				? currentAnswers.filter((answer) => answer !== option)
				: currentAnswers.concat([option]);

			this.setAnswers(...answers);
		} else {
			const answer = isOptionAlreadySelected ? undefined : option;
			this.setAnswers(answer);
		}
	};

	ngOnInit() {
		this.isSingleLineMode = this.question.options.some((option) => option.text.length > 24);
	}
}
