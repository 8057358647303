import { HttpClient } from "@angular/common/http";
import { Injectable, NgModule } from "@angular/core";
import { Translation, TranslocoLoader, TranslocoModule, provideTransloco } from "@ngneat/transloco";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private http: HttpClient) {}

	getTranslation(lang: string) {
		return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
	}
}

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideTransloco({
			config: {
				availableLangs: [
					{
						id: "source",
						label: "Source",
					},
					{
						id: "en",
						label: "English",
					},
					{
						id: "da",
						label: "Danish",
					},
					{
						id: "de",
						label: "German",
					},
					{
						id: "es",
						label: "Spanish",
					},
					{
						id: "fi",
						label: "Finnish",
					},
					{
						id: "fr",
						label: "French",
					},
					{
						id: "no",
						label: "Norwegian",
					},
					{
						id: "pl",
						label: "Polish",
					},
					{
						id: "pt",
						label: "Portuguese",
					},
					{
						id: "sv",
						label: "Swedish",
					},
					{
						id: "zh",
						label: "Chinese",
					},
				],
				defaultLang: "en",
				fallbackLang: "source",
				missingHandler: {
					useFallbackTranslation: true,
				},
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.isProduction,
			},
			loader: TranslocoHttpLoader,
		}),
	],
})
export class TranslocoRootModule {}
