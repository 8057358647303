import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { ChecklistState, ChecklistStateStore } from "./checklist-state.store";

@Injectable({ providedIn: "root" })
export class ChecklistStateQuery extends Query<ChecklistState> {
	constructor(protected override store: ChecklistStateStore) {
		super(store);
	}

	preopeningChecklistSubmittedOn$ = this.select((state) => state.preopeningChecklistSubmittedOn);
}
