import { Component, Input } from "@angular/core";
import { ServiceLocator } from "../../../../app.module";
import { FormQuestion, Question, SubmitResponseAdHocScheduleSlotFromTrigger } from "../../checklist.interface";
import { ChecklistProgressStateQuery } from "../../state/checklist-progress-state.query";
import { ChecklistProgressStateService } from "../../state/checklist-progress-state.service";
import { ControlValueAccessor, FormGroup } from "@angular/forms";
import { PositionService } from "source/app/configuration/services/position.service";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";

@Component({ template: "" })
export class QuestionComponent<T extends Question> implements ControlValueAccessor {
	protected checklistProgressService = ServiceLocator.get(ChecklistProgressStateService);
	protected checklistProgressQuery = ServiceLocator.get(ChecklistProgressStateQuery);
	protected positionService = ServiceLocator.get(PositionService);
	protected locationStateQuery = ServiceLocator.get(LocationStateQuery);

	@Input() question: T;
	@Input() form: FormGroup;

	public answer: FormQuestion | undefined;

	protected setAnswers = async (...answers: string[]): Promise<void> => {
		answers = (answers || []).filter((answer) => answer?.length > 0);

		if (answers.length === 0) {
			answers = undefined;
			this.answer = undefined;
			this.onFormChange(null);
		} else {
			const operator = await this.locationStateQuery.selectOperator$.firstAsync();

			const formAnswer: FormQuestion = {
				answers: answers,
				answered: new Date().toISOString(),
				position: this.positionService.getCurrentPosition(),
				user: operator.id,
				adHocScheduleSlots: this.getAdHocScheduleSlots(answers),
			};
			this.answer = formAnswer;
			this.onFormChange(formAnswer);
		}
	};

	getAdHocScheduleSlots = (answers: string[]) => {
		const adHocScheduleSlots: SubmitResponseAdHocScheduleSlotFromTrigger[] = [];

		const slotTriggers = this.question.triggers.filter(
			(trigger) => trigger.type === "AdHocScheduleSlotTrigger",
		) as SubmitResponseAdHocScheduleSlotFromTrigger[];

		slotTriggers.forEach((trigger) => {
			if (trigger.condition.comparer === "EQ" && answers.includes(trigger.condition.right)) {
				adHocScheduleSlots.push({
					question: this.question.id,
					...trigger,
					condition: trigger.condition,
				});
			}
			if (trigger.condition.comparer === "NEQ" && answers.includes(trigger.condition.right) === false) {
				adHocScheduleSlots.push({
					question: this.question.id,
					...trigger,
					condition: trigger.condition,
				});
			}
		});

		return adHocScheduleSlots;
	};

	//Form Setup
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onFormChange = (_answer: FormQuestion) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};

	disabled = false;

	registerOnChange(fn: (value: FormQuestion) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(answer: FormQuestion | undefined): void {
		if (answer != undefined) {
			this.answer = answer;
		}
	}
}
