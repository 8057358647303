import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { map, timeout, switchMap } from "rxjs/operators";
import { EnvironmentQuery } from "../state/environment.query";
import { LocationStateQuery } from "../state/location-state.query";
import { Location } from "../state/location-state.store";
import { Observable } from "rxjs";
import { Checklist, LocationActivity } from "source/app/features/checklists/checklist.interface";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	constructor(
		private http: HttpClient,
		private environmentQuery: EnvironmentQuery,
		private loadingController: LoadingController,
		private locationStateQuery: LocationStateQuery,
		private translocoService: TranslocoService,
	) {}

	private postToBackend = async (endpoint: string, payload: object, headers?: HttpHeaders) => {
		const environment = await this.environmentQuery.environment$.firstAsync();
		return this.http.post(environment.api.url + endpoint, payload, { headers: headers }).firstAsync();
	};

	private getFromBackend = async <T>(endpoint: string, params: Record<string, string>, headers?: HttpHeaders) => {
		const environment = await this.environmentQuery.environment$.firstAsync();
		return this.http
			.get<ApiResponseWithData<T>>(environment.api.url + endpoint, { params, headers })
			.pipe(map((response) => response.data))
			.firstAsync();
	};

	locationSummary = () => {
		return this.getFromBackend<LocationSummaryResult>("api/rideops/location-summary", {});
	};

	memberships = () => {
		return this.getFromBackend<string[]>("api/rideops/memberships", {});
	};

	getCriticalResults = async (): Promise<CriticalResult[]> =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();

			const parameters = {
				location: location.id,
				startOfDay: this.startOfDay(),
			};

			const reponse = await this.getFromBackend<CriticalResultsResponse>(
				"api/rideops/critical-results",
				parameters,
			);
			return reponse.items;
		});

	login = async (userId: string, pin: string): Promise<void> => {
		const location = await this.locationStateQuery.location$.firstAsync();

		const headers = new HttpHeaders({
			"X-User-Identifier": userId,
			"X-Pin-Code": pin,
		});

		await this.postToBackend("api/rideops/login", { location: location.id, user: userId, operation: 0 }, headers);
	};

	signInAttendant = async (attendant: string, position: number) =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();

			await this.postToBackend("api/rideops/login", {
				location: location.id,
				user: attendant,
				position: position,
				operation: 0,
			});
		});

	signOutAttendant = async (attendant: string, position: number) =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();

			await this.postToBackend("api/rideops/logout", {
				location: location.id,
				user: attendant,
				position: position,
				operation: 1,
			});
		});

	logout = async () =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();
			const environment = await this.environmentQuery.environment$.firstAsync();
			const operator = await this.locationStateQuery.selectOperator$.firstAsync();
			const positions = await this.locationStateQuery.positions$.firstAsync();
			const hasAttendants = positions.some((position) => position.attendant != null);
			const endpoint = environment.api.url + "api/rideops/logout";

			const logoutRequest = {
				user: operator.id,
				location: location.id,
				when: new Date().toISOString(),
				operation: 1,
				signoutAllAttendants: hasAttendants,
			};

			await this.http.post(endpoint, logoutRequest).pipe(timeout(3000)).firstAsync();
		});

	open = () =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();
			await this.postToBackend("api/rideops/open-location", { location: location.id });
		});

	close = (dispatchesAtClose: number, ridersAtClose: number) =>
		this.showLoader(async () => {
			const [[operator, location, settings], carts, seats] =
				await this.locationStateQuery.opsLocOpsSettingsCartsnSeats$.firstAsync();
			const dispatches = await this.locationStateQuery.dispatches$.firstAsync();
			const riders = await this.locationStateQuery.riders$.firstAsync();

			const extraDispatches = dispatchesAtClose - dispatches;
			const extraRiders = ridersAtClose - riders;

			const payload: CloseLocationRequest = {
				location: location.id,
				dispatches: extraDispatches < 0 ? 0 : extraDispatches,
				dispatchRequest: {
					location: location.id,
					when: new Date().toISOString(),
					riders: extraRiders < 0 ? 0 : extraRiders,
					operator: operator.id,
					dispatchUnitCapacity: settings.dispatchUnitCapacity,
					dispatchUnitsAvailable: carts,
					totalUnavailableCapacity: seats,
				},
			};

			await this.postToBackend("api/rideops/close-location", payload);
		});

	down = (description: string, categories: string[] = undefined) =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();

			const payload: CloseLocationRequest = {
				location: location.id,
				createDowntime: true,
				downtimeDescription: description,
				downtimeCategories: categories,
			};

			await this.postToBackend("api/rideops/close-location", payload);
		});

	getDowntimeTemplates = () => {
		return this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();
			return this.getFromBackend<DowntimeTemplate[]>("api/rideops/downtime-templates", { location: location.id });
		});
	};

	changePin = (pin: string) => {
		return this.postToBackend("api/rideops/change-pin-code", { pin: pin });
	};

	getStatistics = async (showLoader = true) => {
		const statisticsCall = async () => {
			const location = await this.locationStateQuery.location$.firstAsync();
			const operator = await this.locationStateQuery.operator$.firstAsync();

			const parameters = {
				location: location.id,
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				startOfShift: operator.loggedIn,
			};

			return this.getFromBackend<LocationStatisticsResult>("api/rideops/location-statistics", parameters);
		};

		if (showLoader) {
			return this.showLoader(statisticsCall);
		}

		return statisticsCall();
	};

	getLibrary = () =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();
			return this.getFromBackend<LibraryResponse>("api/rideops/library", { location: location.id });
		});

	getChecklist = (activity: LocationActivity) =>
		this.showLoader(async () => {
			const location = await this.locationStateQuery.location$.firstAsync();

			const parameters = {
				location: location.id,
				activity: activity,
			};

			return this.getFromBackend<Checklist>("api/rideops/required-checklist", parameters);
		});

	uploadFiles$ = (files: File[], tags: string[] = []): Observable<HttpEvent<FileResponse>> => {
		const payload = new FormData();
		files.forEach((file, index) => payload.append(`file-${index}`, file));

		const environment$ = this.environmentQuery.environment$;
		return environment$.pipe(
			switchMap((environment) => {
				const endpoint = environment.api.url + "api/rideops/files";

				// ServiceWorker does currently not support reporting progress on uploads
				// https://stackoverflow.com/questions/62235319/reportprogress-is-not-working-in-production
				const headers =
					tags.length > 0
						? new HttpHeaders({ "ngsw-bypass": "", Tags: tags.join(",") })
						: new HttpHeaders({ "ngsw-bypass": "" });

				return this.http.post<FileResponse>(endpoint, payload, {
					reportProgress: true,
					observe: "events",
					headers,
				});
			}),
		);
	};

	private startOfDay = (): string => {
		const now = new Date();
		now.setHours(0, 0, 0, 0);
		return now.toISOString();
	};

	private _loader: HTMLIonLoadingElement;
	private _activeRequests = 0;
	private showLoader = async <T>(action: () => Promise<T>): Promise<T> => {
		this._activeRequests++;

		try {
			if (this._activeRequests === 1) {
				const message = this.translocoService.translate("LOADING_MESSAGE__PLEASE_WAIT");
				this._loader = await this.loadingController.create({ message: message });
				this._loader.present();
			}

			return await action();
		} finally {
			this._activeRequests--;
			if (this._activeRequests === 0) {
				this._loader?.dismiss();
			}
		}
	};
}

export interface CloseLocationRequest {
	location: string;
	createDowntime?: boolean;
	downtimeDescription?: string;
	downtimeCategories?: string[];
	dispatches?: number;
	dispatchRequest?: CreateDispatchRequest;
}

export interface CreateDispatchRequest {
	location: string;
	operator: string;
	when: string;
	riders: number;
	dispatchUnitCapacity: number;
	dispatchUnitsAvailable: number;
	totalUnavailableCapacity: number;
}

export interface LocationSummaryResult {
	allowLocationSwitch: boolean;
	locations: Location[];
}

export interface CriticalResultsResponse {
	items: CriticalResult[];
}

export interface CriticalResult {
	result: string;
	checklistName: string;
	answered: string;
	answeredBy: string;
	asset?: string;
}

export interface DowntimeTemplate {
	id: string;
	name: string;
	description: string;
	color: string;
	categories: string[];
}

export interface FileData {
	currentFilename: string;
	extension: string;
	fileRevision: number;
	fromCameraRoll: boolean;
	id: string;
	name: string;
	path: string;
	type: string;
}

export interface FileResponse {
	data: FileData[];
}
