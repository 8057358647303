import { ChangeDetectionStrategy, Component, OnInit, forwardRef } from "@angular/core";
import { DatetimeCustomEvent } from "@ionic/angular";
import { map } from "rxjs";
import { DateQuestion as DateTimeQuestion } from "../../../checklist.interface";
import { QuestionComponent } from "../question.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-date-time-question",
	templateUrl: "./date-time-question.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateTimeQuestionComponent),
			multi: true,
		},
	],
})
export class DateTimeQuestionComponent
	extends QuestionComponent<DateTimeQuestion>
	implements OnInit, ControlValueAccessor
{
	presentation: "date" | "time-date";
	dateTimeSettings$ = this.locationStateQuery.operatorDateTimeSettings$;
	hourCycle$ = this.dateTimeSettings$.pipe(map((settings) => (settings.useTwentyFourHourClock ? "h23" : "h12")));

	onChange = (event: Event) => {
		const value = (<DatetimeCustomEvent>event).target.value;
		const answers = Array.isArray(value) ? value : [value];

		this.setAnswers(...answers);
	};

	ngOnInit() {
		this.presentation = this.question.includeTime ? "time-date" : "date";
	}
}
