import { Injectable } from "@angular/core";
import { ChecklistStateStore } from "./checklist-state.store";

@Injectable({ providedIn: "root" })
export class ChecklistStateService {
	constructor(private checklistStore: ChecklistStateStore) {}

	setPreopeningChecklistSubmittedOn = (submittedOn: Date | string) => {
		if (typeof submittedOn == "object") {
			submittedOn = submittedOn?.toISOString();
		}

		this.checklistStore.update({
			preopeningChecklistSubmittedOn: submittedOn,
		});
	};
}
