import { Injectable } from "@angular/core";
import { distinctUntilChanged, fromEvent, map, merge, Observable, startWith, timeout } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class IdleService {
	private activityEvents = [
		fromEvent(window, "mousemove"),
		fromEvent(window, "resize"),
		fromEvent(document, "keyup"),
		fromEvent(document, "touchend"),
		fromEvent(document, "visibilitychange"),
	];

	private isInactiveAfterMilliseconds: number = 2.5 * 60000; // 2.5 minutes;
	private isInactiveState$: Observable<boolean> = merge(...this.activityEvents).pipe(
		map(() => false),
		timeout({
			each: this.isInactiveAfterMilliseconds,
			with: () => this.isInactiveState$.pipe(startWith(true)),
		}),
		distinctUntilChanged(),
	);

	isInactive$ = this.isInactiveState$.pipe(startWith(false));
}
