import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ChecklistPageWithVisibility } from "../modals/checklist-modal.component";

@Component({
	selector: "app-checklist",
	templateUrl: "./checklist.component.html",
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ChecklistComponent {
	@Input() page!: ChecklistPageWithVisibility;
	@Input() form: FormGroup;
}
